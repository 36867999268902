//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BaseCmsBlock from '../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin'

export default BaseCmsBlock.extend({
  name: "VideoBlock",
  data() {
    return {
      isClick: false,
      screenWidth: 0,
    }
  },

  mounted() {
    this.screenWidth = window.innerWidth;
  },

  computed: {
    imageId() {
      return this.screenWidth <= 986 ? (this.values?.image_mobile || 0) : (this.values?.image || 0)
    },

    getContent() {
      return this.values?.video_html || '';
    },
  },
});

